/* provides a mapping to each nested (or not) endPoint so we can change
   the Redux store without having to change every connected component */

import { PersonalInfo } from '../interfaces/user';
import { RootState } from './store';

export const selectUser = (state: RootState): PersonalInfo => state._newReduxTree.auth.user;
export const stateMappings = (state: RootState) => ({
  auth: state._newReduxTree.auth.auth,
  lastPageUrl: state._newReduxTree.auth.lastPageUrl,
  lastUserId: state._newReduxTree.auth.lastUserId,
  user: state._newReduxTree.auth.user,
  users: state._newReduxTree.user,
  isAuthenticated: !!(
    state._newReduxTree.auth &&
    state._newReduxTree.auth &&
    state._newReduxTree.auth.user.id
  ),
  campaign: state._newReduxTree.campaign,
  traceback: state._newReduxTree.traceback,
  traceforward: state._newReduxTree.traceforward,
  partners: state._newReduxTree.partners,
  messaging: state._newReduxTree.messaging,
  configuration: state._newReduxTree.configuration,
  reputation: state._newReduxTree.reputation,
  report: state._newReduxTree.report,
  incident: state._newReduxTree.incident,
  hop: state._newReduxTree.hop,
  tfhop: state._newReduxTree.tfhop,
  notification: state._newReduxTree.notification,
  provider: state._newReduxTree.provider,
  govFollowers: state._newReduxTree.govFollowers,
  comment: state._newReduxTree.comment,
  zeroBounce: state._newReduxTree.zeroBounce.zeroBounceResponse,
  rmd: state._newReduxTree.rmd,
  dno: state._newReduxTree.dno,
  loading:
    state._newReduxTree.user.loading > 0 ||
    state._newReduxTree.notification.loading > 0 ||
    state._newReduxTree.traceback.loading > 0 ||
    state._newReduxTree.traceforward.loading > 0 ||
    state._newReduxTree.campaign.loading > 0 ||
    state._newReduxTree.auth.loading > 0 ||
    state._newReduxTree.hop.loading > 0 ||
    state._newReduxTree.tfhop.loading > 0 ||
    state._newReduxTree.provider.loading > 0 ||
    state._newReduxTree.comment.loading > 0 ||
    state._newReduxTree.partners.loading > 0 ||
    state._newReduxTree.accessLogs.loading > 0 ||
    state._newReduxTree.report.loading > 0 ||
    state._newReduxTree.incident.loading > 0 ||
    state._newReduxTree.appinfo.loading > 0 ||
    state._newReduxTree.configuration.loading > 0 ||
    state._newReduxTree.stirShaken.loading > 0 ||
    state._newReduxTree.insight.loading > 0 ||
    state._newReduxTree.attachments.loading > 0 ||
    state._newReduxTree.reputation.loading > 0 ||
    state._newReduxTree.request.loading > 0 ||
    state._newReduxTree.community.loading > 0,
  loadingTree: {
    user: state._newReduxTree.user.loading > 0,
    traceback: state._newReduxTree.traceback.loading > 0,
    traceforward: state._newReduxTree.traceforward.loading > 0,
    campaign: state._newReduxTree.campaign.loading > 0,
    auth: state._newReduxTree.auth.loading > 0,
    hop: state._newReduxTree.hop.loading > 0,
    tfhop: state._newReduxTree.tfhop.loading > 0,
    notification: state._newReduxTree.notification.loading > 0,
    provider: state._newReduxTree.provider.loading > 0,
    comment: state._newReduxTree.comment.loading > 0,
    partners: state._newReduxTree.partners.loading > 0,
    accessLogs: state._newReduxTree.accessLogs.loading > 0,
    report: state._newReduxTree.report.loading > 0,
    incident: state._newReduxTree.incident.loading > 0,
    appinfo: state._newReduxTree.appinfo.loading > 0,
    configuration: state._newReduxTree.configuration.loading > 0,
    stirShaken: state._newReduxTree.stirShaken.loading > 0,
    insight: state._newReduxTree.insight.loading > 0,
    attachments: state._newReduxTree.attachments.loading > 0,
    reputation: state._newReduxTree.reputation.loading > 0,
    request: state._newReduxTree.request.loading > 0,
    community: state._newReduxTree.community > 0
  },
  error: {
    user: state._newReduxTree.user.error,
    auth: state._newReduxTree.auth.error,
    campaign: state._newReduxTree.campaign.error,
    traceback: state._newReduxTree.traceback.error,
    traceforward: state._newReduxTree.traceback.error,
    hop: state._newReduxTree.hop.error,
    tfhop: state._newReduxTree.tfhop.error,
    notification: state._newReduxTree.notification.error,
    provider: state._newReduxTree.provider.error,
    partners: state._newReduxTree.partners.error,
    comment: state._newReduxTree.comment.error,
    accessLogs: state._newReduxTree.accessLogs.error,
    report: state._newReduxTree.report.error,
    incident: state._newReduxTree.incident.error,
    appinfo: state._newReduxTree.appinfo.error,
    configuration: state._newReduxTree.configuration.error,
    stirShaken: state._newReduxTree.stirShaken.error,
    insight: state._newReduxTree.insight.error,
    attachments: state._newReduxTree.attachments.error,
    request: state._newReduxTree.request.error,
    community: state._newReduxTree.community.error
  },
  appinfo: state._newReduxTree.appinfo,
  accessLogs: state._newReduxTree.accessLogs,
  changePasswordError: state._newReduxTree.auth.changePasswordError,
  stirShaken: state._newReduxTree.stirShaken,
  insight: state._newReduxTree.insight,
  attachments: state._newReduxTree.attachments,
  request: state._newReduxTree.request,
  community: state._newReduxTree.community
});
