import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { stateMappings } from '../../../redux/stateMappings';
import { Button, Card, CardBody, CardFooter } from 'reactstrap';
import { AegisResponse } from '../../../interfaces/community';
import {
  generateAegisCommunityScore,
  getAegisCommunityScore
} from '../../../redux/community/thunks';

interface IProps {
  communityAppId: number;
  aegisScore: AegisResponse;
  getAegisCommunityScore: Function;
  generateAegisCommunityScore: Function;
}

const AegisCard: FC<IProps> = ({
  communityAppId,
  aegisScore,
  getAegisCommunityScore,
  generateAegisCommunityScore
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [, setInProgressTimeOut] = useState<ReturnType<typeof setInterval> | null>(null);
  useEffect(() => {
    communityAppId && getAegisCommunityScore(communityAppId);
  }, [communityAppId]);
  useEffect(() => {
    if (aegisScore.communityId) {
      if (!aegisScore.isPopulated) {
        const interval = setInterval(
          async () => await getAegisCommunityScore(communityAppId),
          10000
        );
        setInProgressTimeOut(interval);
        return () => {
          clearInterval(interval);
        };
      } else {
        setInProgressTimeOut((timeout) => {
          if (timeout) clearInterval(timeout);
          return null;
        });
      }
    }
  }, [aegisScore.isPopulated]);
  const handleGenerate = async () => {
    await generateAegisCommunityScore(communityAppId);
    await getAegisCommunityScore(communityAppId);
  };
  return (
    <Card className="aegis-card">
      <CardBody>
        {aegisScore.communityId ? (
          aegisScore.isPopulated ? (
            <>
              <div className="h5 font-weight-bold text-center">{`Score: ${aegisScore.score} out of 100`}</div>
              <div className="h6">{`Score Type: ${aegisScore.type}`}</div>
              <h6 className="p-0 m-0" onClick={() => setShowDetails(!showDetails)} role="button">
                <i
                  className={`${showDetails ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'} pe-2 custom-blue`}
                ></i>
                Details
              </h6>
              {showDetails && <div className="overflow-auto">{aegisScore.details}</div>}
            </>
          ) : (
            <div className="h5 text-center">Data is loading</div>
          )
        ) : (
          <div className="h5 text-center">Not Calculated</div>
        )}
      </CardBody>
      <CardFooter className="d-flex justify-content-center">
        <div className="d-flex flex-column">
          <div>Score provided by</div>
          <img
            className="w-50"
            src="https://aegismobile.com/wp-content/uploads/2018/04/Artboard-1.png"
            alt="Aegis Mobile"
          />
          <Button
            className="telecom-btn ps-0 pe-0 mt-2"
            onClick={handleGenerate}
            disabled={!!aegisScore.communityId && !aegisScore.isPopulated}
          >
            Generate Aegis Score
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return { aegisScore: sm.community.aegisScore };
};
const mapActionsToProps = { getAegisCommunityScore, generateAegisCommunityScore };
export default connect(mapStateToProps, mapActionsToProps)(AegisCard);
