import { Community } from './actionEnum';

const defaultState: any = {
  applications: [],
  totalApplications: 0,
  application: {},
  aegisScore: {},
  loading: 0,
  error: ''
};

const reducer = (state = defaultState, action: any) => {
  switch (action.type) {
    case Community.GET_APPLICATIONS_INFO:
      return {
        ...state,
        applications: action.payload.data || [],
        totalApplications: action.payload.count
      };

    case Community.GET_APPLICATION:
      return {
        ...state,
        application: action.payload || {}
      };

    case Community.GET_AEGIS_SCORE:
      return {
        ...state,
        aegisScore: action.payload || {}
      };

    case Community.SET_LOADING_STATUS:
      if (action.payload) return { ...state, loading: state.loading + 1 };
      else return { ...state, loading: state.loading === 0 ? state.loading : state.loading - 1 };

    case Community.SET_ERROR_MESSAGE:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
export default reducer;
