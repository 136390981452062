import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Breadcrumb from '../components/Breadcrumbs';
import ProviderEditor from '../components/providerEditor';
import FollowProviderCheckbox from '../components/shared/FollowProviderCheckbox';
import Legend from '../components/shared/Legend';
import ZendeskWebWidget from '../components/shared/ZendeskWebWidget';
import { ViewMode } from '../enum/ViewMode';
import { userTypes } from '../enum/userTypes';
import { Provider, ProviderType } from '../interfaces/provider';
import { PersonalInfo } from '../interfaces/user';
import { signOut } from '../redux/auth/thunks';
import {
  deleteProvider,
  editProviderObject,
  getProviderObject,
  updatePendingProviderObject
} from '../redux/provider/thunks';
import { stateMappings } from '../redux/stateMappings';

interface IProps {
  attachments: any[];
  createdBy: string;
  editProviderObject: Function;
  error: string;
  provider: Provider;
  getProviderObject: Function;
  user: PersonalInfo;
  providerTypes: ProviderType[];
  deleteProvider: Function;
  signOut: Function;
  isLoading: boolean;
}

const getManuallyProviderFields = (provider: Provider) => {
  let fields = [];

  if (provider.RMDs && provider.RMDs.length > 0) {
    const rmd = provider.RMDs[0];

    if (!rmd.businessName && !!provider.name) {
      fields.push({ field: 'Company Name', value: provider.name });
    }

    if (!rmd.robocallMitigationContactName && !!provider.contactName) {
      fields.push({ field: 'Manager Name', value: provider.contactName });
    }

    if (!rmd.country && !!provider.country) {
      fields.push({ field: 'Country', value: provider.country });
    }

    if (!rmd.businessAddress && !!provider.address1) {
      fields.push({ field: 'Address 1', value: provider.address1 });
    }

    if (!rmd.contactEmail && !!provider.contactEmail) {
      fields.push({ field: 'Contact Email', value: provider.contactEmail });
    }

    if (!rmd.contactCity && !!provider.city) {
      fields.push({ field: 'City', value: provider.city });
    }

    if (!rmd.contactTelephoneNumber && !!provider.contactPhone) {
      fields.push({ field: 'Manager Phone', value: provider.contactPhone });
    }
  }

  return fields;
};
const ProviderDetail: React.FC<IProps> = ({
  editProviderObject,
  attachments,
  provider,
  providerTypes,
  deleteProvider,
  user,
  isLoading,
  getProviderObject,
  error,
  signOut
}) => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [viewMode, setViewMode] = useState(hash === '#edit' ? ViewMode.EDIT : ViewMode.VIEW);
  const [modifyTooltip, setModifyTooltip] = useState(!localStorage.getItem('ModifyHopTip'));
  const [isDelete, setIsDelete] = useState(false);
  const { id } = useParams();
  const providerId = Number(id || 0);
  const magicLinkLogin = useMemo(() => !!localStorage.getItem('magicLinkLogIn'), []);

  useEffect(() => {
    if (error) {
      navigate('/404');
    }
    getProviderObject(providerId);
  }, [error]);

  useEffect(() => {
    switch (provider.status) {
      case 0:
        setViewMode(ViewMode.VIEW);
        break;
      case 1:
        setViewMode(provider.communityId ? ViewMode.COMMUNITY_APPROVAL : ViewMode.APPROVAL);
        break;
      case 2:
        setViewMode(ViewMode.REJECTED);
        break;
    }
  }, [provider]);

  const handleClickEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (magicLinkLogin) {
      signOut();
      return;
    }
    setViewMode(viewMode === ViewMode.VIEW ? ViewMode.EDIT : ViewMode.VIEW);
  };

  const handleSummaryClick = () => {
    navigate(`/providers/provider/summaries/${provider.providerId}`);
  };
  const handleInsightClick = () => {
    navigate(`/providers/provider/insights/${provider.providerId}`);
  };

  const handleModifyTooltipBtnClick = () => {
    localStorage.setItem('deleteCampaignTip', 'SET');
    setModifyTooltip(false);
  };

  const deactivateConfirmModalToggle = () => {
    setIsDelete(!isDelete);
  };

  const manuallyProviderFields = getManuallyProviderFields(provider);

  return (
    <Fragment>
      {!isLoading && viewMode !== ViewMode.REJECTED && (
        <Fragment>
          <FollowProviderCheckbox
            className="d-flex provider-title-container justify-content-between"
            providerId={Number(providerId)}
          >
            <Breadcrumb
              title="providers"
              className="table-breadcrumbs"
              destination={
                user.roleType === userTypes.Admin
                  ? 'providers'
                  : 'providers/provider/summaries/' + provider.providerId
              }
              detail={provider.name || 'Unknown Provider'}
              modify={viewMode !== ViewMode.VIEW ? 'Deactivate Provider' : ''}
              modifyFunction={() => deactivateConfirmModalToggle()}
              tooltipContents={
                <Fragment>
                  <p>
                    If a mistake has been made or you need to deactivate this provider for some
                    other reason, this is the spot to do that.
                  </p>
                  <p>After being deactivated, the provider will no longer be visible or active.</p>
                </Fragment>
              }
              isModifyTooltip={modifyTooltip}
              handleModifyTooltipBtnClick={handleModifyTooltipBtnClick}
            />
          </FollowProviderCheckbox>
          <Card className="telecom-card">
            {user.roleType === userTypes.Admin && viewMode === ViewMode.APPROVAL && (
              <Fragment>
                <div className="alert alert-success m-0" role="alert">
                  <p>
                    <span className="font-calibri-bold">
                      {provider.createdBy.name} [{provider.createdBy.email}]
                    </span>{' '}
                    has requested that the provider listed below be added to the system. Please
                    approve or reject this new provider.
                  </p>
                  <p>
                    To <span className="font-calibri-bold">approve</span> this provider, please
                    complete the additional mandatory fields below, and{' '}
                    <span className="font-calibri-bold">Save</span> this form.
                  </p>
                  <p>
                    To <span className="font-calibri-bold">reject</span> this provider, select{' '}
                    <span className="font-calibri-bold">Reject New Provider</span>, below, and
                    optionally, provide a reason. This will generate an email response to the
                    requesting provider.
                  </p>
                  {manuallyProviderFields.length > 0 && (
                    <>
                      <p>
                        <i className="fa fa-asterisk asterisk" />
                        {''}
                        The following fields were not provided by the RMD and were added manually:
                      </p>
                      {manuallyProviderFields.map((item) => (
                        <Fragment key={item.field}>
                          {item.field}: {item.value}
                          <br />
                        </Fragment>
                      ))}
                    </>
                  )}
                </div>
              </Fragment>
            )}
            <CardHeader className="telecom-card-header">
              <div className="d-flex justify-content-between m-0">
                {provider.name || 'Unknown Provider'}
                {user.roleType === userTypes.Admin && (
                  <div>
                    <Button color="primary" className="px-2 py-0 me-2" onClick={handleSummaryClick}>
                      Upstream Summary
                    </Button>
                    <Button color="primary" className="px-2 py-0" onClick={handleInsightClick}>
                      Insight
                    </Button>
                  </div>
                )}
              </div>
            </CardHeader>
            <CardBody className="card-detail-provider">
              <ProviderEditor
                providerTypes={providerTypes}
                className={viewMode === ViewMode.VIEW ? 'provider-editor' : ''}
                viewMode={viewMode}
                attachments={attachments}
                providerProp={provider}
                providerServer={editProviderObject}
                getProviderObject={getProviderObject}
                approvalComplete={() => setViewMode(ViewMode.VIEW)}
              />
              {viewMode === ViewMode.VIEW && (
                <Fragment>
                  <div className="d-flex justify-content-center">
                    <Button className="telecom-btn" onClick={handleClickEdit}>
                      {`${magicLinkLogin ? 'Confirm Password' : 'Edit'}`}
                    </Button>
                  </div>
                  {magicLinkLogin && (
                    <div className="d-flex justify-content-center">
                      <span className="text-danger mt-2">
                        You are accessing the site with a magic-link, some features will be disabled
                        until you sign in with your password
                      </span>
                    </div>
                  )}
                </Fragment>
              )}
            </CardBody>
          </Card>
          <div className="d-flex justify-content-center pt-4 pb-4">
            <Legend />
          </div>
          <Modal
            centered
            isOpen={isDelete}
            className="submit-confirm-modal"
            toggle={deactivateConfirmModalToggle}
          >
            <ModalHeader toggle={deactivateConfirmModalToggle} />
            <ModalBody className="delete-modal-body">
              <h5 className="traceback-sub-title">Deactivate this provider?</h5>
              <p className="telecom-text mb-0">The provider will no longer be visible or active.</p>
            </ModalBody>
            <ModalFooter className="m-auto">
              <Row>
                <Button
                  className="btn-default telecom-btn"
                  color="light"
                  onClick={deactivateConfirmModalToggle}
                >
                  Cancel
                </Button>
                <Button
                  className="telecom-btn red"
                  onClick={() => {
                    deleteProvider(provider.providerId);
                  }}
                >
                  Proceed
                </Button>
              </Row>
            </ModalFooter>
          </Modal>
        </Fragment>
      )}
      {viewMode === ViewMode.REJECTED && (
        <Fragment>
          <div className="text-center mt-2">
            <h1>Provider Rejected</h1>
            <p>{provider.name} has already been rejected.</p>
          </div>
        </Fragment>
      )}
      <ZendeskWebWidget />
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);

  return {
    provider: sm.provider.provider,
    providerTypes: sm.provider.providerTypes,
    attachments: sm.provider.attachments,
    createdBy: sm.user.name,
    user: sm.user,
    error: sm.provider.error,
    isLoading: sm.provider.status
  };
};

const mapActionsToProps = {
  getProviderObject,
  editProviderObject,
  updatePendingProviderObject,
  deleteProvider,
  signOut
};

export default connect(mapStateToProps, mapActionsToProps)(ProviderDetail);
