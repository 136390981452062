import { Community } from './actionEnum';

export const getApplicationsAction = (response: any) => ({
  type: Community.GET_APPLICATIONS_INFO,
  payload: response
});

export const getApplicationAction = (response: any) => ({
  type: Community.GET_APPLICATION,
  payload: response
});

export const getAegisCommunityScoreAction = (response: any) => ({
  type: Community.GET_AEGIS_SCORE,
  payload: response
});

export const setLoadingStatus = (candidate: boolean) => ({
  type: Community.SET_LOADING_STATUS,
  payload: candidate
});

export const setErrorMessage = (message: string) => ({
  type: Community.SET_ERROR_MESSAGE,
  payload: message
});
