import { CreateProviderRequest } from '../../interfaces/community';
import { Pagination } from '../../interfaces/pagination';
import { ApiRequest, buildParams } from '../../lib/apiRequest';

export const registerUser = async (data: any) =>
  ApiRequest.request({
    method: 'POST',
    url: `/public/community/join`,
    data
  });

export const getApplicationStatus = async (id: string) =>
  ApiRequest.request({
    method: 'GET',
    url: `/public/community/${id}`
  });

export const getApplicationsApiCall = async (params: Pagination) =>
  await ApiRequest.request({
    method: 'GET',
    url:
      `/community/get?` +
      buildParams({
        ...params
      })
  });

export const getApplicationApiCall = async (id: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/community/application/${id}`
  });

export const createProviderCommunityApiCall = async (data: CreateProviderRequest) =>
  ApiRequest.request({
    method: 'POST',
    url: `/community/createProvider/${data.communityId}`,
    data
  });

export const generateAegisCommunityScoreApiCall = async (id: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/aegis/generateCommunity/${id}`
  });

export const getAegisCommunityScoreApiCall = async (id: number) =>
  ApiRequest.request({
    method: 'GET',
    url: `/aegis/getAegisCommunity/${id}`
  });
