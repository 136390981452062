import { combineReducers } from 'redux';
import accessLogs from './accesslogs/reducer';
import { default as appinfo, default as reputationInfo } from './appinfo/reducer';
import attachments from './attachments/reducer';
import auth from './auth/reducer';
import campaign from './campaign/reducer';
import comment from './comment/reducer';
import configuration from './configuration/reducer';
import dno from './dno/reducer';
import govFollowers from './govFollower/reducer';
import hop from './hop/reducer';
import incident from './incident/reducer';
import insight from './insights/reducer';
import messaging from './messaging/reducer';
import notification from './notification/reducer';
import partners from './partners/reducer';
import provider from './provider/reducer';
import report from './report/reducer';
import reputation from './reputation/reducer';
import request from './request/reducer';
import rmd from './rmd/reducer';
import stirShaken from './stirShaken/reducer';
import tfhop from './tfhop/reducer';
import traceback from './traceback/reducer';
import traceforward from './traceforward/reducer';
import user from './user/reducer';
import zeroBounce from './zeroBounce/reducer';
import community from './community/reducer';

const rootReducer = combineReducers({
  auth,
  campaign,
  traceback,
  traceforward,
  hop,
  tfhop,
  provider,
  govFollowers,
  comment,
  appinfo,
  report,
  configuration,
  reputationInfo,
  incident,
  reputation,
  accessLogs,
  partners,
  messaging,
  zeroBounce,
  rmd,
  dno,
  user,
  notification,
  stirShaken,
  insight,
  attachments,
  request,
  community
});

export default rootReducer;
