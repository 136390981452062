export interface JoinFormData {
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  businessName: string;
  dbaName: string;
  countryOfTax: string;
  taxId: string;
  companyType: string;
  stockTicker: string;
  businessAddress: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
  };
  websiteUrl: string;
  mainPhone: string;
  fccFiling: string;
  rmd: string;
  networkInfo: {
    averageDailyTraffic: string;
    yearsInBusiness: string;
    annualRevenue: string;
    numberOfEmployees: string;
    countriesOfOperation: string[];
    trafficProviders: string;
    providerType: string;
  };
}

export type NestedPaths<T> = T extends object
  ? {
      [K in keyof T]: K extends string | number
        ? T[K] extends object
          ? `${K}` | `${K}.${NestedPaths<T[K]>}`
          : `${K}`
        : never;
    }[keyof T]
  : never;

export interface ApplicationStatus {
  applicationEmail: string;
  applicationCreateDate: string;
  status: number;
}

export const statusType: { [key: number]: string } = {
  1: 'Pending',
  2: 'Accepted',
  3: 'Rejected'
};

export interface ApplicationInfo {
  applicationID: number;
  applicationCreateDate: string;
  providerId?: number;
  providerName?: string;
  status: number;
  aegisRiskScore?: string;
}

export interface CommunityApplication {
  id: number;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactPhone: string;
  businessName: string;
  dbaName: string;
  countryOfTax: string;
  taxId: string;
  companyType: string;
  stockTicker: string;
  businessAddress1: string;
  businessAddress2: string;
  businessCity: string;
  businessState: string;
  businessCountry: string;
  businessPostalCode: string;
  websiteUrl: string;
  mainPhone: string;
  fccFiling: string;
  rmd: string;
  averageDailyTraffic: string;
  yearsInBusiness: string;
  annualRevenue: string;
  numberOfEmployees: string;
  countriesOfOperation: string[];
  trafficProviders: string;
  providerType: string;
  status: number;
  userId: number;
  providerId: number;
  reason: string;
}

export interface AegisResponse {
  details: string;
  score: string;
  communityId: number;
  providerId: number;
  type: string;
  isPopulated: boolean;
}

export enum CreateProviderType {
  existingProvider = 'existingProvider',
  newProvider = 'newProvider',
  refuseProvider = 'refuseProvider'
}

export interface ProviderItemCommunity {
  id: any;
  name: string;
  country: string;
}

export interface CreateProviderRequest {
  communityId: number;
  existingProviderId?: number;
  communityStatus: number;
  communityRejectReason?: string;
}
