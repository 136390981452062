import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import { createProviderCommunity, getApplication } from '../redux/community/thunks';
import { stateMappings } from '../redux/stateMappings';
import {
  CommunityApplication,
  CreateProviderRequest,
  CreateProviderType,
  ProviderItemCommunity,
  statusType
} from '../interfaces/community';
import AegisCard from '../components/AdminDashboard/Community/AegisCard';
import CustomSelect, { SelectOption } from '../components/CustomSelect';
import { getProviderNamesCommunityList } from '../redux/provider/thunks';
import InputFormGroup from '../components/inputFormGroup';
import InputError from '../components/inputError';

interface IProps {
  application: CommunityApplication;
  providerNameCommunityList: ProviderItemCommunity[];
  getApplication: Function;
  getProviderNamesCommunityList: Function;
  createProviderCommunity: Function;
  createError: string;
}
const CommunityAppPage: FC<IProps> = ({
  application,
  getApplication,
  providerNameCommunityList,
  getProviderNamesCommunityList,
  createProviderCommunity,
  createError
}) => {
  const { id } = useParams();
  const [createOption, setCreateOption] = useState('');
  const [selectedProviderId, setSelectedProviderId] = useState(0);
  const [reason, setReason] = useState('');
  const [error, setError] = useState('');
  const existingProvidersOption = useMemo(
    () =>
      providerNameCommunityList &&
      providerNameCommunityList.map(
        (provider: ProviderItemCommunity): SelectOption => ({
          value: provider.id,
          label: `${provider.name} (${provider.country}) `
        })
      ),
    [providerNameCommunityList]
  );
  useEffect(() => {
    getApplication(Number(id));
    getProviderNamesCommunityList();
  }, []);
  useEffect(() => {
    setSelectedProviderId(application.providerId || 0);
  }, [application.providerId]);
  useEffect(() => {
    if (createError === 'contact exists error') setError(createError);
  }, [createError]);
  const handleCreateOptions = (e: any) => {
    setCreateOption(e.target.value);
  };
  const findExistingProviderOption = (providerId: number) =>
    providerNameCommunityList.find((provider: ProviderItemCommunity) => provider.id === providerId)
      ?.name || 'Already in use';
  const saveEnabled =
    (createOption === CreateProviderType.existingProvider && selectedProviderId) ||
    createOption === CreateProviderType.newProvider ||
    (createOption === CreateProviderType.refuseProvider && reason);
  const createProviderFromCommunity = () => {
    const request: CreateProviderRequest = {
      communityId: application.id,
      communityStatus: createOption === CreateProviderType.refuseProvider ? 3 : 2,
      existingProviderId:
        createOption === CreateProviderType.existingProvider ? selectedProviderId : 0,
      communityRejectReason: createOption === CreateProviderType.refuseProvider ? reason : ''
    };
    createProviderCommunity(request);
  };
  return (
    <Card className="telecom-card mt-5">
      <CardHeader className="telecom-card-header">{`Community Application ${application.id}`}</CardHeader>
      <CardBody className="mx-3">
        <div className="row ms-2 mb-2">
          <div className="col-md-3">
            <label className="telecom-label">Community Application Status</label>
            <span className="ms-2">{statusType[application?.status as number]}</span>
          </div>
          {application.reason && (
            <div className="col-md-2">
              <label className="telecom-label">Reject Reason</label>
              <span className="ms-2">{application.reason}</span>
            </div>
          )}
        </div>
        <div className="row">
          <div className="row">
            <h5 className="traceback-sub-title mb-3">Contact Details</h5>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">First Name</label>
            <div className="ms-2">{application.contactFirstName}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Last Name</label>
            <div className="ms-2">{application.contactLastName}</div>
          </div>
          <div className="col-md-3">
            <label className="telecom-label">Email Address</label>
            <div className="ms-2">{application.contactEmail}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Phone Number</label>
            <div className="ms-2">{application.contactPhone}</div>
          </div>
          {application.userId && (
            <div className="col-md-1">
              <label className="telecom-label">User ID</label>
              <div className="ms-2">{application.userId}</div>
            </div>
          )}
          {application.providerId && (
            <div className="col-md-2">
              <label className="telecom-label">Provider ID</label>
              <div className="ms-2">{application.providerId}</div>
            </div>
          )}
        </div>
        <div className="row mt-4">
          <div className="row">
            <h5 className="traceback-sub-title mb-3">Voice Service Provider</h5>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Business Name</label>
            <div className="ms-2">{application.businessName}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Affiliated Name</label>
            <div className="ms-2">{application.dbaName}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Country of Tax</label>
            <div className="ms-2">{application.countryOfTax}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Tax ID</label>
            <div className="ms-2">{application.taxId}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Company Type</label>
            <div className="ms-2">{application.companyType}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Stock Ticker Symbol</label>
            <div className="ms-2">{application.stockTicker}</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="row">
            <h5 className="traceback-sub-title mb-3">Business Address</h5>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Address 1</label>
            <div className="ms-2">{application.businessAddress1}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Address 2</label>
            <div className="ms-2">{application.businessAddress2}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">City</label>
            <div className="ms-2">{application.businessCity}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Zip Code</label>
            <div className="ms-2">{application.businessPostalCode}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Country</label>
            <div className="ms-2">{application.businessCountry}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">State</label>
            <div className="ms-2">{application.businessState}</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="row">
            <h5 className="traceback-sub-title mb-3">Other Information</h5>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Website URL</label>
            <div className="ms-2">{application.websiteUrl}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Main Phone</label>
            <div className="ms-2">{application.mainPhone}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">FCC 499 Filing</label>
            <div className="ms-2">{application.fccFiling}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">RMD</label>
            <div className="ms-2">{application.rmd}</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="row">
            <h5 className="traceback-sub-title mb-3">Network Information</h5>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Average Daily Traffic</label>
            <div className="ms-2">{application.averageDailyTraffic}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Years in Business</label>
            <div className="ms-2">{application.yearsInBusiness}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Annual Revenue</label>
            <div className="ms-2">{application.annualRevenue}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Number of Employees</label>
            <div className="ms-2">{application.numberOfEmployees}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Countries of Operation</label>
            <div className="ms-2">{application.countriesOfOperation}</div>
          </div>
          <div className="col-md-2">
            <label className="telecom-label">Provider Type</label>
            <div className="ms-2">{application.providerType}</div>
          </div>
          <div className="col-md-5 mt-1">
            <label className="telecom-label">
              Providers that current accept or send traffic to
            </label>
            <div className="ms-2">{application.trafficProviders}</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col">
            <h5 className="traceback-sub-title">Business Risk</h5>
            <AegisCard communityAppId={application.id} />
          </div>
          {application.status === 1 && (
            <div className="col">
              <h5 className="traceback-sub-title">Create Provider</h5>
              <div className="create-provider">
                <div className="d-flex">
                  <input
                    type="radio"
                    value={CreateProviderType.existingProvider}
                    className="me-2"
                    onChange={handleCreateOptions}
                    checked={createOption === CreateProviderType.existingProvider}
                  />
                  {`Accept Application & Choose Existing Provider ${application.providerId ? `(${findExistingProviderOption(application.providerId)})` : ''}`}
                </div>
                {createOption === CreateProviderType.existingProvider &&
                  !application.providerId && (
                    <div className="col-9 col-md-5 ms-5 mb-3 mt-1">
                      <CustomSelect
                        className="customselect-small"
                        classNamePrefix="customselect"
                        placeholder="Select Provider"
                        typeLabel=""
                        selectedOption={selectedProviderId}
                        getOptions={existingProvidersOption}
                        setSelectedOption={(e: any) => {
                          setSelectedProviderId(e.value);
                        }}
                      />
                    </div>
                  )}
                {!application.providerId && (
                  <div className="d-flex">
                    <input
                      type="radio"
                      value={CreateProviderType.newProvider}
                      className="me-2"
                      onChange={handleCreateOptions}
                      checked={createOption === CreateProviderType.newProvider}
                    />
                    Accept Application & Create New Provider
                  </div>
                )}
                <div className="d-flex">
                  <input
                    type="radio"
                    value={CreateProviderType.refuseProvider}
                    className="me-2"
                    onChange={handleCreateOptions}
                    checked={createOption === CreateProviderType.refuseProvider}
                  />
                  Reject Application
                </div>
                {createOption === CreateProviderType.refuseProvider && (
                  <div>
                    <InputFormGroup
                      isTextarea
                      inputName="reason"
                      inputId="reason"
                      inputClassName="input-comment"
                      inputValue={reason}
                      inputOnChange={(e) => {
                        if (e && e.currentTarget) setReason(e.currentTarget.value);
                      }}
                      inputAutoComplete="off"
                      containerClassName="col-md-10"
                    />
                  </div>
                )}
                {error && <InputError className="p-0">{error}</InputError>}
                <div className="d-flex justify-content-center mt-2">
                  <Button
                    className="telecom-btn"
                    disabled={!saveEnabled}
                    onClick={createProviderFromCommunity}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    application: sm.community.application,
    createError: sm.community.error,
    providerNameCommunityList: sm.provider.providerNamesCommunity
  };
};

const mapActionsToProps = {
  getApplication,
  getProviderNamesCommunityList,
  createProviderCommunity
};

export default connect(mapStateToProps, mapActionsToProps)(CommunityAppPage);
