export enum Provider {
  GET_PROVIDERS = 'GET_PROVIDERS',
  GET_PROVIDER = 'GET_PROVIDER',
  GET_PROVIDERS_COUNTRIES = 'GET_PROVIDERS_COUNTRIES',
  ADD_PROVIDER = 'ADD_PROVIDER',
  EDIT_PROVIDER = 'EDIT_PROVIDER',
  UPDATE_PENDING_PROVIDER = 'UPDATE_PENDING_PROVIDER',
  ADD_ATTACHMENTS = 'ADD_ATTACHMENTS',
  GET_ATTACHMENTS = 'GET_ATTACHMENTS',
  GET_PROVIDER_CONTACTS = 'GET_PROVIDER_CONTACTS',
  GET_PROVIDER_TYPES = 'GET_PROVIDER_TYPES',
  SET_PROVIDER_CONTACTS = 'SET_PROVIDER_CONTACTS',
  GET_PROVIDER_LIST = 'GET_PROVIDER_LIST',
  GET_PROVIDER_SUMMARY_LIVE = 'GET_PROVIDER_SUMMARY_LIVE',
  GET_PROVIDER_SUMMARY_PRERECORDED = 'GET_PROVIDER_SUMMARY_PRERECORDED',
  GET_PROVIDER_SUMMARY_INTERNATIONAL = 'GET_PROVIDER_SUMMARY_INTERNATIONAL',
  GET_PROVIDER_UPSTREAM_SUMMARY = 'GET_PROVIDER_UPSTREAM_SUMMARY',
  GET_PROVIDER_STATS = 'GET_PROVIDER_STATS',
  GET_ALL_PROVIDERS_STATS = 'GET_ALL_PROVIDERS_STATS',
  GET_AVERAGE_DAILY_TRAFFIC_STATS = 'GET_AVERAGE_DAILY_TRAFFIC_STATS',
  GET_PROVIDER_ACTIONS_STATS = 'GET_PROVIDER_ACTIONS_STATS',
  SET_PROVIDER_LOADING_STATUS = 'SET_PROVIDER_LOADING_STATUS',
  SET_PROVIDER_ERROR_MESSAGE = 'SET_PROVIDER_ERROR_MESSAGE',
  GET_PROVIDER_NAMES = 'GET_PROVIDER_NAMES',
  GET_PROVIDER_NAMES_COMMUNITY = 'GET_PROVIDER_NAMES_COMMUNITY',
  GET_PROVIDERS_NR_STATS = 'GET_PROVIDERS_NR_STATS',
  GET_PROVIDERS_ORG_STATS = 'GET_PROVIDERS_ORG_STATS',
  GET_PROVIDER_NR = 'GET_PROVIDER_NR',
  GET_NEW_PROVIDERS_INFO = 'GET_NEW_PROVIDERS_INFO',
  GET_NR_PROVIDERS_INFO = 'GET_NR_PROVIDERS_INFO',
  GET_NF_PROVIDERS_INFO = 'GET_NF_PROVIDERS_INFO',
  GET_PROVIDERS_IP_INFO = 'GET_PROVIDERS_IP_INFO',
  GET_PROVIDERS_STATES = 'GET_PROVIDERS_STATES',
  GET_PROVIDER_STIRSHAKEN_STATS = 'GET_PROVIDER_STIRSHAKEN_STATS',
  GET_PROVIDER_COMMENTS = 'GET_PROVIDER_COMMENTS'
}
