import { Reputation } from './reputation';
import { RMDEntry } from './rmd';
import { EmailValidation, UserDetails } from './user';

export interface Provider {
  active: boolean;
  address1: string;
  address2: string;
  approved: boolean;
  //   comments: Comment[];
  city: string;
  create_date?: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  country: string;
  fccFiler: string;
  isAdminister: boolean;
  isITGMember: boolean;
  isDomestic: boolean;
  name: string;
  notes: string;
  OCNs: OCNs[];
  RMDs: RMDEntry[];
  dnoAccess?: boolean;
  rmdLinks: string[];
  providerId: number;
  providerType: number;
  providerTypeDetail: string;
  reputation: Reputation;
  state: string;
  status: number;
  tbEmailFormat: number;
  timeZone: string;
  traceStyle: string;
  updateFlag: number;
  warningLevel: number;
  zipCode: string;
  createdById: number;
  createdBy: UserDetails;
  updatedBy: number;
  rejectionReason: string;
  existingId: number;
  rejectDispute?: boolean;
  providerTaxId?: string;

  tenXInternationalMapping?: TenxInternationalProviderMapping[];
  communityId?: number;
}

export interface TenxInternationalProviderMapping {
  nnid: string;
  providerId: number;
  nn_sp_name: string;
  nn_np_name: string;
  country_name: string;
}

export interface ProviderSummaryRow {
  provider?: Provider;
  summary: Summary;
}

export interface NewProviderDetail {
  providerId: number;
  dateAdded: string;
  providerName: string;
  country: string;
  providerRmd?: string;
  rmdSysId?: string;
  rmdDeclaration: string;
  identifiedBy: string;
  identifierId: number;
}

export interface NrOrNfProviderDetail {
  providerId: number;
  provider: string;
  country: string;
  state?: string;
  providerRmd?: string;
  rmdSysId?: string;
  lastNotification: string;
  nrTracebacks: number;
  immediateDownstream: string;
  immediateId: number;
  actionTaken?: string;
  explanation?: string;
  note?: string;
}

export interface NewOrNrProviderDetailPayload {
  startDate: string;
  endDate: string;
  searchedProvider: number;
  isNr?: boolean;
}

export function emptyProvider(): Provider {
  return {
    active: false,
    // comments: [],
    address1: '',
    address2: '',
    approved: false,
    city: '',
    //create_date: '',
    contactEmail: '',
    contactName: '',
    contactPhone: '',
    country: '',
    fccFiler: '',
    isAdminister: false,
    isITGMember: false,
    isDomestic: false,
    name: '',
    notes: '',
    OCNs: [],
    RMDs: [],
    dnoAccess: false,
    rmdLinks: [],
    providerId: 0,
    providerType: 0,
    providerTypeDetail: '',
    reputation: {
      label: '',
      color: '',
      score: 0,
      no_origin: 0,
      no_noResp: 0,
      no_poe: 0,
      no_dsOrigin: 0,
      no_dsNr: 0
    },
    state: '',
    status: 0,
    tbEmailFormat: 0,
    timeZone: '',
    traceStyle: '',
    updateFlag: 0,
    warningLevel: 0,
    zipCode: '',
    createdById: 0,
    createdBy: {
      name: '',
      email: ''
    },
    updatedBy: 0,
    rejectionReason: '',
    existingId: 0,
    providerTaxId: '',
    communityId: 0
  };
}

export interface ProviderPost {
  name: string;
  providerType: number;
  providerTypeDetail: string;
  isITGMember?: boolean;
  dnoAccess?: boolean;
  active?: boolean;
  status: number;
  tbEmailFormat: number;
  address1: string;
  address2: string;
  RMDs: RMDEntry[];
  city: string;
  state: string;
  zipCode: string;
  country: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  fccFiler: string;
  isAdminister: boolean;
  warningLevel: number;
  traceStyle: string;
  notes: string;
  updateFlag: number;
  createdById: number;
  createdBy: UserDetails;
  updatedBy: number;
  rejectionReason: string;
  rejectDispute?: boolean;
  providerTaxId?: string;
  tenXInternationalMapping?: TenxInternationalProviderMapping[];
  timeZone: string;
}

export function mapProviderToProviderPost(source: Provider): ProviderPost {
  return {
    name: source.name,
    providerType: source.providerType,
    providerTypeDetail: source.providerTypeDetail,
    isITGMember: source.isITGMember,
    dnoAccess: source.dnoAccess,
    status: source.status,
    tbEmailFormat: source.tbEmailFormat,
    address1: source.address1,
    address2: source.address2,
    RMDs: source.RMDs.map((v) => ({
      id: v.id,
      number: v.number,
      intermediateProvider: v.intermediateProvider,
      voiceServiceProviderChoice: v.voiceServiceProviderChoice,
      sysId: v.sysId
    })),
    city: source.city,
    state: source.state,
    zipCode: source.zipCode,
    country: source.country,
    contactName: source.contactName,
    contactEmail: source.contactEmail,
    contactPhone: source.contactPhone,
    fccFiler: source.fccFiler,
    isAdminister: source.isAdminister,
    warningLevel: source.warningLevel,
    traceStyle: source.traceStyle,
    notes: source.notes,
    updateFlag: source.updateFlag,
    createdById: source.createdById,
    createdBy: source.createdBy,
    updatedBy: source.updatedBy,
    rejectionReason: source.rejectionReason,
    active: source.active,
    rejectDispute: source.rejectDispute,
    providerTaxId: source.providerTaxId,
    tenXInternationalMapping: source.tenXInternationalMapping,
    timeZone: source.timeZone
  };
}

export interface ProviderContact {
  active: boolean;
  contactId: number;
  create_date?: string;
  email: string;
  escalate: number;
  isAdded?: boolean;
  isEdited?: boolean;
  isEscalationEmail: boolean;
  emailValidation?: EmailValidation;
  isTBEmail: boolean;
  LockoutEnd?: string;
  name: string;
  phone: string;
  providerId: number;
  report: number;
  Role?: string;
  update_date?: string;
  updateFlag: number;
  Username?: string;
  previousProviderId?: number;
}

export interface ProviderContactPost {
  email: string;
  escalate: number;
  isEscalationEmail: boolean;
  isTBEmail: boolean;
  active: boolean;
  name: string;
  phone: string;
  providerId: number;
  report: number;
  updateFlag: number;
}

export function mapProviderContactToProviderContactPost(
  source: ProviderContact
): ProviderContactPost {
  return {
    email: source.email,
    escalate: source.escalate,
    isEscalationEmail: source.isEscalationEmail,
    isTBEmail: source.isTBEmail,
    active: source.active,
    name: source.name,
    phone: source.phone,
    providerId: source.providerId,
    report: source.report,
    updateFlag: source.updateFlag
  };
}

export interface ProviderType {
  description: string;
  name: string;
  value: number;
}

export interface ProviderName {
  id: number;
  isITGMember: boolean;
  name: string;
}

export interface ProvidersCountries {
  providerCountry: string;
  tracebacksPerCountry: number;
}

export interface Summary {
  avgResponseMinOfHops: number;
  numOfCampaigns: number;
  numberOfHops: number;
  numOfNoResponseHops: number;
  numOfNotFoundHops: number;
  numOfOpenHops: number;
  numOfOriginHops: number;
  existOriginHopWithShakenErrors: boolean;
  numOfTermHops: number;
  numOfTransitHops: number;
  numOfUSPoEHops: number;
  numOfFPDHops: number;
  numOfDownstreamHops: number;
  numOfDownstreamOriginHops: number;
  numOfDownstreamNoResponseHops: number;
  lastActivity: string;
  numOfHopsWithRMDFlag?: number;
}

export function emptyProviderSummary(): Summary {
  return {
    avgResponseMinOfHops: 0,
    numOfCampaigns: 0,
    numberOfHops: 0,
    numOfNoResponseHops: 0,
    numOfNotFoundHops: 0,
    numOfOpenHops: 0,
    numOfOriginHops: 0,
    existOriginHopWithShakenErrors: false,
    numOfTermHops: 0,
    numOfTransitHops: 0,
    numOfUSPoEHops: 0,
    numOfFPDHops: 0,
    numOfDownstreamHops: 0,
    numOfDownstreamOriginHops: 0,
    numOfDownstreamNoResponseHops: 0,
    lastActivity: ''
  };
}

export function showExpandableRowForSummary(summary: Summary): boolean {
  return (
    !!summary &&
    (!!summary.numOfNoResponseHops ||
      !!summary.numOfNotFoundHops ||
      !!summary.numOfOriginHops ||
      !!summary.numOfTermHops ||
      !!summary.numOfTransitHops ||
      !!summary.numOfUSPoEHops ||
      !!summary.numOfDownstreamHops ||
      !!summary.numOfDownstreamOriginHops ||
      !!summary.numOfDownstreamNoResponseHops)
  );
}

export interface UpstreamSummaryRow {
  id: number;
  isDomestic: boolean;
  isITGMember: boolean;
  name: string;
  numOfCampaigns: number;
  numberOfHops: number;
  numOfNoResponseHops: number;
  numOfNotFoundHops: number;
  numOfOpenHops: number;
  numOfOriginHops: number;
  numOfTermHops: number;
  numOfTransitHops: number;
  numOfUSPoEHops: number;
}

export interface OCNs {
  createDate: string;
  OCN: string;
  providerId: number;
  updateDate: string;
  updatedBy: string;
}

export interface MatchParams {
  id: string;
}

export interface ProviderNRRow {
  id: number;
  name: string;
  country: number;
  RMDs: RMDEntry[];
  isDomestic: boolean;
  isITGMember: boolean;
}
export interface ProviderOrgRow {
  id: number;
  name: string;
  country: number;
  isIntermediate: boolean;
  rmd_number: string | undefined;
  sys_id: string | undefined;
  isDomestic: boolean;
  isITGMember: boolean;
}
export interface ProviderNames {
  badEmails: boolean;
  id: number;
  name: string;
  noRmd: false;
}

export interface ProviderIpInfo {
  id: number;
  hopId: number;
  providerId: number;
  query: string;
  country: string;
  countryCode: string;
  region: string;
  regionName: string;
  isp: string;
  organization: string;
  mobile: boolean;
  proxy: boolean;
  hosting: boolean;
  create_date: string;
}
export interface ProviderIpInfoRequest {
  providerId: number;
  startDate: string;
  endDate: string;
}

export interface ProviderStirShakenStats {
  totalTracebacks: number;
  stirShakenSigner: number;
  intermediateStirshakenSigner: number;
  errorSigningCount: number;
  issuerErrorCount: number;
  notInCallPathCount: number;
  signedNotInCallPathCount: number;
}

export interface ProviderFollowingRequest {
  startDate: string;
  endDate: string;
  chosenProviders: number[];
}
