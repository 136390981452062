import React, { useEffect, useState } from 'react';
import { FC } from 'react';
import { Card, CardBody, CardHeader, Label } from 'reactstrap';
import CustomDataTable from '../../CustomDataTable';
import { TableColumn } from 'react-data-table-component';
import { ApplicationInfo, statusType } from '../../../interfaces/community';
import { connect } from 'react-redux';
import { stateMappings } from '../../../redux/stateMappings';
import { getApplicationsInfo } from '../../../redux/community/thunks';
import { DateFormat } from '../../../enum/DateFormat';
import { getClientFormattedDate } from '../../../lib/utilities';
import { defaultPagination, Pagination } from '../../../interfaces/pagination';

interface IProps {
  applications: ApplicationInfo[];
  totalApplications: number;
  getApplicationsInfo: Function;
}
const columns: TableColumn<ApplicationInfo>[] = [
  {
    name: 'Application ID',
    selector: () => 'id',
    sortable: true,
    grow: 2,
    cell: (application: ApplicationInfo) => (
      <div className="table-cell center">
        <a className="span-cell blue" href={`/community/application/${application.applicationID}`}>
          {application.applicationID}
        </a>
      </div>
    )
  },
  {
    name: 'Application Date',
    selector: () => 'created_date',
    sortable: true,
    grow: 2,
    cell: (application: ApplicationInfo) => (
      <div className="table-cell center">
        {getClientFormattedDate(application?.applicationCreateDate, DateFormat.ShortBoth)}
      </div>
    )
  },
  {
    name: 'Application Status',
    sortable: false,
    grow: 2,
    cell: (application: ApplicationInfo) => (
      <div className="table-cell center">{statusType[application?.status as number]}</div>
    )
  },
  {
    name: 'Provider',
    selector: () => 'providerName',
    sortable: true,
    grow: 2,
    cell: (application: ApplicationInfo) => (
      <div className="table-cell center">
        {application.providerId && (
          <a className="span-cell blue" href={`/providers/provider/${application.providerId}`}>
            {application.providerName}
          </a>
        )}
      </div>
    )
  },
  {
    name: 'Aegis Risk',
    sortable: false,
    grow: 2,
    cell: (application: ApplicationInfo) => (
      <div className="table-cell center bold">
        {application.aegisRiskScore && `${application.aegisRiskScore} out of 100`}
      </div>
    )
  }
];
const AdminCommunity: FC<IProps> = ({ applications, totalApplications, getApplicationsInfo }) => {
  const [paginationParams, setPaginationParams] = useState({
    ...defaultPagination(),
    sort: 'id',
    order: 'desc',
    pageSize: 10
  });
  useEffect(() => {
    getApplicationsInfo(paginationParams);
  }, [paginationParams]);
  const updatePagination = (params: Pagination) => {
    setPaginationParams({ ...paginationParams, ...params });
  };
  return (
    <Card>
      <CardHeader>
        <Label className="telecom-label extra-size mb-3">Community Applications</Label>
      </CardHeader>
      <CardBody className="card-body-traceback">
        <CustomDataTable
          columns={columns}
          tableData={applications}
          defaultSortFieldId={paginationParams.sort}
          defaultPage={paginationParams.page}
          defaultPageSize={paginationParams.pageSize}
          paginationTotalRows={totalApplications}
          updatePaginationParams={updatePagination}
          pagination
        />
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state: any) => {
  const sm = stateMappings(state);
  return {
    applications: sm.community.applications,
    totalApplications: sm.community.totalApplications
  };
};

const mapActionsToProps = {
  getApplicationsInfo
};

export default connect(mapStateToProps, mapActionsToProps)(AdminCommunity);
