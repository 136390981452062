import React, { FC, useEffect, useState } from 'react';
import BaseCard from '../components/BaseCard';
import { Card, CardBody, Container, Label } from 'reactstrap';
import { useNavigate, useParams } from 'react-router';
import { ApplicationStatus, statusType } from '../interfaces/community';
import { getClientFormattedDate } from '../lib/utilities';
import { DateFormat } from '../enum/DateFormat';
import { getApplicationStatus } from '../redux/community/apiCalls';

const JoinStatus: FC = () => {
  const { hash } = useParams();
  const navigate = useNavigate();
  const [applicationStatus, setApplicationStatus] = useState<ApplicationStatus>();
  useEffect(() => {
    const f = async () => {
      try {
        const data = await getApplicationStatus(hash || '');
        setApplicationStatus(data.data);
      } catch (error) {
        navigate('/join');
      }
    };
    f();
  }, []);
  return (
    <Container>
      <BaseCard containerClassnames="card-join" headerTitle="Application Status">
        <Card className="status-card">
          <CardBody>
            <div className="row">
              <div className="col-12 col-md-4">
                <div className="row">
                  <Label className="telecom-label">Application Email</Label>
                </div>
                <div className="row ps-2">{applicationStatus?.applicationEmail}</div>
              </div>
              <div className="col-12 col-md-4">
                <div className="row">
                  <Label className="telecom-label">Application Status</Label>
                </div>
                <div className="row ps-2">{statusType[applicationStatus?.status as number]}</div>
              </div>
              <div className="col-12 col-md-4">
                <div className="row">
                  <Label className="telecom-label">Application Date</Label>
                </div>
                <div className="row ps-2">
                  {getClientFormattedDate(
                    applicationStatus?.applicationCreateDate,
                    DateFormat.ShortBoth
                  )}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </BaseCard>
    </Container>
  );
};

export default JoinStatus;
