import { CreateProviderRequest } from '../../interfaces/community';
import { Pagination } from '../../interfaces/pagination';
import { logoutOnAuthError } from '../../lib/apiRequest';
import {
  getAegisCommunityScoreAction,
  getApplicationAction,
  getApplicationsAction,
  setErrorMessage,
  setLoadingStatus
} from './actions';
import {
  createProviderCommunityApiCall,
  generateAegisCommunityScoreApiCall,
  getAegisCommunityScoreApiCall,
  getApplicationApiCall,
  getApplicationsApiCall
} from './apiCalls';

const setStatus = (status: boolean, message: string) => async (dispatch: any) => {
  dispatch(setErrorMessage(message));
  dispatch(setLoadingStatus(status));
};

export const getApplicationsInfo = (params: Pagination) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const data = await getApplicationsApiCall(params);
    if (data && data.data) {
      dispatch(getApplicationsAction(data.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getApplication = (id: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const data = await getApplicationApiCall(id);
    if (data && data.data) {
      dispatch(getApplicationAction(data.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const getAegisCommunityScore = (id: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    const data = await getAegisCommunityScoreApiCall(id);
    if (data && data.data) {
      dispatch(getAegisCommunityScoreAction(data.data));
    }
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const generateAegisCommunityScore = (id: number) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    await generateAegisCommunityScoreApiCall(id);
    dispatch(setStatus(false, ''));
  } catch (error: any) {
    dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};

export const createProviderCommunity = (data: CreateProviderRequest) => async (dispatch: any) => {
  dispatch(setStatus(true, ''));
  try {
    await createProviderCommunityApiCall(data);
    dispatch(setStatus(false, ''));
    window.location.href = '/admin-dashboard#community';
  } catch (error: any) {
    if (error.response.data.error) dispatch(setStatus(false, error.response.data.error));
    else dispatch(setStatus(false, error.message));
    logoutOnAuthError(error);
  }
};
