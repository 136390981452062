import React, { ChangeEvent, MouseEvent, useState } from 'react';
import { RegionDropdown } from 'react-country-region-selector';
import { useNavigate } from 'react-router';
import { OnChangeValue, SingleValue } from 'react-select';
import { Button, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import BaseCard from '../components/BaseCard';
import CustomSelect, { SelectOption } from '../components/CustomSelect';
import CountryDropdown from '../components/HopDetail/CountryDropdown';
import MultiCountryDropdown from '../components/HopDetail/MultiCountryDropdown';
import InputError from '../components/inputError';
import InputFormGroup from '../components/inputFormGroup';
import { validatePhoneNumber } from '../lib/phoneNumber';
import { validEmailRegex, validUrlRegex } from '../lib/regex';
import { JoinFormData, NestedPaths } from '../interfaces/community';
import { registerUser } from '../redux/community/apiCalls';

const setNestedValue = <T extends Record<string, any>>(
  obj: T,
  path: NestedPaths<T>,
  value: any
): void => {
  const keys = path.split('.') as (keyof T)[];
  keys.reduce((current, key, index) => {
    if (index === keys.length - 1) {
      current[key] = value;
    } else {
      current[key] = current[key] && typeof current[key] === 'object' ? current[key] : {};
    }
    return current[key];
  }, obj as any);
};

interface FormErrors {
  [key: string]: string | FormErrors | undefined;
}

const validateForm = (data: JoinFormData): FormErrors => {
  const errors: FormErrors = {};

  const requiredFields = [
    'contactFirstName',
    'contactLastName',
    'contactEmail',
    'contactPhone',
    'businessName',
    'dbaName',
    'countryOfTax',
    'taxId',
    'companyType',
    'websiteUrl',
    'mainPhone',
    'fccFiling',
    'rmd'
  ];

  requiredFields.forEach((field) => {
    if (!data[field as keyof JoinFormData]) {
      errors[field] = 'This field is required';
    }
  });

  const nestedFields = {
    businessAddress: ['address1', 'city', 'state', 'country', 'postalCode'],
    networkInfo: [
      'averageDailyTraffic',
      'yearsInBusiness',
      'annualRevenue',
      'numberOfEmployees',
      'countriesOfOperation',
      'trafficProviders',
      'providerType'
    ]
  };

  Object.entries(nestedFields).forEach(([key, fields]) => {
    const nestedErrors: FormErrors = {};
    fields.forEach((field) => {
      if (!data[key as keyof JoinFormData][field as keyof (typeof data)[keyof JoinFormData]]) {
        nestedErrors[field] = 'This field is required';
      }
    });
    if (Object.keys(nestedErrors).length > 0) {
      errors[key] = nestedErrors;
    }
  });

  if (!data.networkInfo.trafficProviders.length)
    errors.networkInfo = {
      ...(errors.networkInfo as FormErrors),
      trafficProviders: 'This field is required'
    };

  if (!data.networkInfo.countriesOfOperation.length)
    errors.networkInfo = {
      ...(errors.networkInfo as FormErrors),
      countriesOfOperation: 'This field is required'
    };

  if (!errors.contactEmail && !validEmailRegex.test(data.contactEmail)) {
    errors.contactEmail = 'Please provide a valid email address';
  }

  if (!errors.contactPhone && !validatePhoneNumber(data.contactPhone)) {
    errors.contactPhone = 'Please provide a valid phone number';
  }

  if (!errors.mainPhone && !validatePhoneNumber(data.mainPhone)) {
    errors.mainPhone = 'Please provide a valid phone number';
  }

  if (!errors.websiteUrl && !validUrlRegex.test(data.websiteUrl)) {
    errors.websiteUrl = 'Please provide a valid url';
  }

  return errors;
};

const providerTypes: SelectOption[] = [
  { label: 'Transit', value: 'Transit' },
  { label: 'Voice Service', value: 'Voice Service' },
  { label: 'Call Center', value: 'Call Center' }
];

const companyTypes: SelectOption[] = [
  { label: 'Private', value: 'Private' },
  { label: 'Public', value: 'Public' },
  { label: 'Government', value: 'Government' }
];

const Join: React.FC = () => {
  const navigate = useNavigate();

  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [formData, setFormData] = useState<JoinFormData>({
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactPhone: '',
    businessName: '',
    dbaName: '',
    countryOfTax: '',
    taxId: '',
    companyType: 'Private',
    stockTicker: '',
    businessAddress: {
      address1: '',
      address2: '',
      city: '',
      state: '',
      country: '',
      postalCode: ''
    },
    websiteUrl: '',
    mainPhone: '',
    fccFiling: '',
    rmd: '',
    networkInfo: {
      averageDailyTraffic: '',
      yearsInBusiness: '',
      annualRevenue: '',
      numberOfEmployees: '',
      countriesOfOperation: [],
      trafficProviders: '',
      providerType: ''
    }
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    // Nested object handling
    if (name.includes('.')) {
      const keys = name.split('.');
      setFormData((prev) => {
        const updated: JoinFormData = { ...prev };
        setNestedValue(updated, name as NestedPaths<JoinFormData>, value);
        return updated;
      });
      setFormErrors((prev) => {
        const updated: FormErrors = { ...prev };
        setNestedValue(updated, name as NestedPaths<FormErrors>, '');
        return updated;
      });
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
      setFormErrors((prev) => ({ ...prev, [name]: '' }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validateForm(formData);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      const f = async () => {
        try {
          await registerUser(formData);
        } catch (error) {
          console.log(error);
        }
      };
      f();
    } else {
    }
  };

  const cancel = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate('/');
  };

  return (
    <Container>
      <BaseCard containerClassnames="card-join" headerTitle="Set Up My Account">
        <Row className="justify-content-center">
          <Col xs="12" sm="9" md="7" lg="6">
            <Form onSubmit={handleSubmit}>
              <h2>Contact</h2>
              <div className="d-flex flex-row justify-content-evenly">
                <div className="col-6 me-2">
                  <InputFormGroup
                    inputName="contactFirstName"
                    inputPlaceholder="First Name"
                    label="First Name"
                    inputValue={formData.contactFirstName}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={formErrors.contactFirstName}
                  />
                </div>
                <div className="col-6">
                  <InputFormGroup
                    inputName="contactLastName"
                    inputPlaceholder="Last Name"
                    label="Last Name"
                    inputValue={formData.contactLastName}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={formErrors.contactLastName}
                  />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-evenly">
                <div className="col-6 me-2">
                  <InputFormGroup
                    type="email"
                    inputName="contactEmail"
                    inputPlaceholder="Email Address"
                    label="Email Address"
                    inputValue={formData.contactEmail}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={formErrors.contactEmail}
                  />
                </div>
                <div className="col-6">
                  <InputFormGroup
                    type="tel"
                    inputName="contactPhone"
                    inputPlaceholder="Phone Number"
                    label="Phone Number"
                    inputValue={formData.contactPhone}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={formErrors.contactPhone}
                  />
                </div>
              </div>

              <h2>Voice Service Provider</h2>
              <div className="d-flex flex-row justify-content-evenly">
                <div className="col-6 me-2">
                  <InputFormGroup
                    inputName="businessName"
                    inputPlaceholder="Business Name / Legal Name"
                    label="Business Name / Legal Name"
                    inputValue={formData.businessName}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={formErrors.businessName}
                  />
                </div>
                <div className="col-6">
                  <InputFormGroup
                    inputName="dbaName"
                    inputPlaceholder="Affiliated Name or DBA"
                    label="Affiliated Name or DBA"
                    inputValue={formData.dbaName}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={formErrors.dbaName}
                  />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-center">
                <FormGroup className="col-6 me-2 align-self-stretch">
                  <Label className="telecom-label">
                    Country of Tax
                    <i className="fa fa-asterisk asterisk" />
                  </Label>
                  <CountryDropdown
                    className="country-dropdown"
                    name="hopdetail-country-dropdown"
                    value={formData.countryOfTax}
                    setCountry={(country: string) => {
                      setFormData((v) => ({ ...v, countryOfTax: country }));
                    }}
                    extraOption
                  />
                  {formErrors.countryOfTax && (
                    <InputError className="telecom-input-error">
                      {formErrors.countryOfTax as string}
                    </InputError>
                  )}
                </FormGroup>
                <div className="col-6">
                  <InputFormGroup
                    inputName="taxId"
                    inputPlaceholder="Tax ID, EIN/TIN"
                    label="Tax ID, EIN/TIN"
                    inputValue={formData.taxId}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={formErrors.taxId}
                  />
                </div>
              </div>

              <div className="d-flex flex-row justify-content-center">
                <FormGroup className="col-6 me-2 align-self-stretch">
                  <Label className="telecom-label">
                    Company Type
                    <i className="fa fa-asterisk asterisk" />
                  </Label>
                  <CustomSelect
                    typeLabel={''}
                    getOptions={companyTypes}
                    selectedOption={formData.companyType}
                    setSelectedOption={function (
                      value: OnChangeValue<SelectOption, boolean>
                    ): void {
                      const m = value as SingleValue<SelectOption>;
                      setFormData((v) => ({ ...v, companyType: m?.value }));
                    }}
                  />
                  {formErrors.companyType && (
                    <InputError className="telecom-input-error">
                      {formErrors.companyType as string}
                    </InputError>
                  )}
                </FormGroup>
                <div className="col-6">
                  <InputFormGroup
                    inputName="stockTicker"
                    inputPlaceholder="Stock Ticker Symbol"
                    label="Stock Ticker Symbol"
                    inputValue={formData.stockTicker}
                    inputOnChange={handleChange}
                    errorMessage={formErrors.stockTicker}
                  />
                </div>
              </div>

              <h2>Business Address</h2>

              <div className="d-flex flex-row justify-content-evenly">
                <div className="col-6 me-2">
                  <InputFormGroup
                    inputName="businessAddress.address1"
                    inputPlaceholder="Address 1"
                    label="Address 1"
                    inputValue={formData.businessAddress.address1}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={(formErrors.businessAddress as FormErrors)?.address1}
                  />
                </div>
                <div className="col-6">
                  <InputFormGroup
                    inputName="businessAddress.address2"
                    inputPlaceholder="Address 2"
                    label="Address 2"
                    inputValue={formData.businessAddress.address2}
                    inputOnChange={handleChange}
                    errorMessage={(formErrors.businessAddress as FormErrors)?.address2}
                  />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-evenly">
                <div className="col-6 me-2">
                  <InputFormGroup
                    inputName="businessAddress.city"
                    inputPlaceholder="City"
                    label="City"
                    inputValue={formData.businessAddress.city}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={(formErrors.businessAddress as FormErrors)?.city}
                  />
                </div>
                <div className="col-6">
                  <InputFormGroup
                    inputName="businessAddress.postalCode"
                    inputPlaceholder="Postal / Zip Code"
                    label="Postal / Zip Code"
                    inputValue={formData.businessAddress.postalCode}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={(formErrors.businessAddress as FormErrors)?.postalCode}
                  />
                </div>
              </div>

              <div className="d-flex flex-row justify-content-evenly">
                <FormGroup className="col-6 me-2 align-self-stretch">
                  <Label className="telecom-label">
                    Country
                    <i className="fa fa-asterisk asterisk" />
                  </Label>
                  <CountryDropdown
                    className="country-dropdown"
                    name="hopdetail-country-dropdown"
                    value={formData.businessAddress.country}
                    setCountry={(country: string) => {
                      setFormData((v) => ({
                        ...v,
                        businessAddress: { ...v.businessAddress, country: country }
                      }));
                    }}
                    extraOption
                  />
                  {(formErrors.businessAddress as FormErrors)?.country && (
                    <InputError className="telecom-input-error">
                      {(formErrors.businessAddress as FormErrors)?.country as string}
                    </InputError>
                  )}
                </FormGroup>
                <FormGroup className="col-6 align-self-stretch">
                  <Label className="telecom-label">State / Region</Label>
                  <RegionDropdown
                    country={formData.businessAddress.country}
                    value={formData.businessAddress.state}
                    className="region-dropdown"
                    name="hopdetail-region-dropdown"
                    onChange={(e) => {
                      setFormData((v) => ({
                        ...v,
                        businessAddress: { ...v.businessAddress, state: e }
                      }));
                    }}
                  />
                  {(formErrors.businessAddress as FormErrors)?.state && (
                    <InputError className="telecom-input-error">
                      {(formErrors.businessAddress as FormErrors)?.state as string}
                    </InputError>
                  )}
                </FormGroup>
              </div>

              <h2>Other Information</h2>
              <div className="d-flex flex-row justify-content-evenly">
                <div className="col-6 me-2">
                  <InputFormGroup
                    type="url"
                    inputName="websiteUrl"
                    inputPlaceholder="Website URL"
                    label="Website URL"
                    inputValue={formData.websiteUrl}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={formErrors.websiteUrl}
                  />
                </div>
                <div className="col-6">
                  <InputFormGroup
                    type="tel"
                    inputName="mainPhone"
                    inputPlaceholder="Main Phone"
                    label="Main Phone"
                    inputValue={formData.mainPhone}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={formErrors.mainPhone}
                  />
                </div>
              </div>

              <div className="d-flex flex-row justify-content-evenly">
                <div className="col-6 me-2">
                  <InputFormGroup
                    inputName="fccFiling"
                    inputPlaceholder="FCC 499 Filing #"
                    label="FCC 499 Filing"
                    inputValue={formData.fccFiling}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={formErrors.fccFiling}
                  />
                </div>
                <div className="col-6">
                  <InputFormGroup
                    inputName="rmd"
                    inputPlaceholder="RMD #"
                    label="RMD"
                    inputValue={formData.rmd}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={formErrors.rmd}
                  />
                </div>
              </div>

              <h2>Network Information</h2>
              <div className="d-flex flex-row justify-content-evenly">
                <div className="col-6 me-2">
                  <InputFormGroup
                    inputName="networkInfo.averageDailyTraffic"
                    inputPlaceholder="Average Daily Traffic / Call Volume"
                    label="Average Daily Traffic / Call Volume"
                    inputValue={formData.networkInfo.averageDailyTraffic}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={(formErrors.networkInfo as FormErrors)?.averageDailyTraffic}
                  />
                </div>
                <div className="col-6">
                  <InputFormGroup
                    type="number"
                    inputName="networkInfo.yearsInBusiness"
                    inputPlaceholder="Years in Business"
                    label="Years in Business"
                    inputValue={formData.networkInfo.yearsInBusiness}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={(formErrors.networkInfo as FormErrors)?.yearsInBusiness}
                  />
                </div>
              </div>
              <div className="d-flex flex-row justify-content-evenly">
                <div className="col-6 me-2">
                  <InputFormGroup
                    inputName="networkInfo.annualRevenue"
                    inputPlaceholder="Annual Revenue"
                    label="Annual Revenue"
                    inputValue={formData.networkInfo.annualRevenue}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={(formErrors.networkInfo as FormErrors)?.annualRevenue}
                  />
                </div>
                <div className="col-6">
                  <InputFormGroup
                    type="number"
                    inputName="networkInfo.numberOfEmployees"
                    inputPlaceholder="Number of Employees"
                    label="Number of Employees"
                    inputValue={formData.networkInfo.numberOfEmployees}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={(formErrors.networkInfo as FormErrors)?.numberOfEmployees}
                  />
                </div>
              </div>

              <div className="d-flex flex-row justify-content-center">
                <FormGroup className="col-6 me-2 align-self-stretch">
                  <Label className="telecom-label">
                    Countries of operation
                    <i className="fa fa-asterisk asterisk" />
                  </Label>
                  <MultiCountryDropdown
                    className="country-dropdown"
                    values={formData.networkInfo.countriesOfOperation}
                    setCountry={(country: string[]) => {
                      setFormData((v) => ({
                        ...v,
                        networkInfo: { ...v.networkInfo, countriesOfOperation: country }
                      }));
                    }}
                    extraOption
                  />
                  {(formErrors.networkInfo as FormErrors)?.countriesOfOperation && (
                    <InputError className="telecom-input-error">
                      {(formErrors.networkInfo as FormErrors)?.countriesOfOperation as string}
                    </InputError>
                  )}
                </FormGroup>
                <FormGroup className="col-6 me-2 align-self-stretch">
                  <Label className="telecom-label">
                    Select Provider Type
                    <i className="fa fa-asterisk asterisk" />
                  </Label>
                  <CustomSelect
                    typeLabel={''}
                    getOptions={providerTypes}
                    selectedOption={formData.networkInfo.providerType}
                    setSelectedOption={function (
                      value: OnChangeValue<SelectOption, boolean>
                    ): void {
                      const m = value as SingleValue<SelectOption>;
                      setFormData((v) => ({
                        ...v,
                        networkInfo: { ...v.networkInfo, providerType: m?.value }
                      }));
                    }}
                  />
                  {(formErrors.networkInfo as FormErrors)?.providerType && (
                    <InputError className="telecom-input-error">
                      {(formErrors.networkInfo as FormErrors)?.providerType as string}
                    </InputError>
                  )}
                </FormGroup>
              </div>
              <div className="d-flex flex-row justify-content-center">
                <div className="flex-fill align-self-stretch">
                  <InputFormGroup
                    inputName="networkInfo.trafficProviders"
                    inputPlaceholder="Providers that current accept or send traffic to"
                    label="Providers that current accept or send traffic to"
                    inputValue={formData.networkInfo.trafficProviders}
                    inputOnChange={handleChange}
                    isRequired
                    errorMessage={(formErrors.networkInfo as FormErrors)?.trafficProviders}
                    isTextarea
                  />
                </div>
              </div>

              <Row className="justify-content-center">
                <Button className="btn-join" color="light" onClick={cancel}>
                  Cancel
                </Button>
                <Button type="submit" className="btn-join">
                  Save
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
      </BaseCard>
    </Container>
  );
};

export default Join;
