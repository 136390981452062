import { allCountries, CountryName, countryTuples } from 'country-region-data';
import React from 'react';
import { MultiValue } from 'react-select';
import CustomPicky from '../CustomPicky';
import { SelectOption } from '../CustomSelect';

const extraOptions: SelectOption[] = [{ label: 'Unknown', value: 'UW' }];
const defaultOption: SelectOption[] = [{ label: 'All Countries', value: '' }];
const priorityOptions: SelectOption[] = [
  { label: 'United States', value: 'US' },
  { label: 'Canada', value: 'CA' }
];
const removeOption: string = 'Puerto Rico';
const getOptions = (extraOption?: boolean, allSelection?: boolean): SelectOption[] => {
  const countries = allCountries
    .map((item): SelectOption => ({ label: item[0], value: item[1] }))
    .concat(extraOption ? extraOptions : [])
    .filter(
      (item) => !priorityOptions.some((v) => item.label === v.label) && item.label !== removeOption
    );
  return allSelection
    ? defaultOption.concat(priorityOptions).concat(countries)
    : priorityOptions.concat(countries);
};
export const getCountryCodeByName = (countryName: CountryName): string => {
  const countryTuple = countryTuples.find(([name]) => name === countryName);
  return countryTuple ? countryTuple[1] : '';
};

interface IProps2 {
  className: string;
  classNamePrefix?: string;
  values: string[];
  setCountry: (a: string[]) => void;
  extraOption?: boolean;
  placeholder?: string;
  allSelection?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  numberDisplayed?: number;
}

const MultiCountryDropdown: React.FC<IProps2> = ({
  className,
  values,
  setCountry,
  extraOption,
  placeholder,
  classNamePrefix,
  allSelection,
  isSearchable,
  isClearable,
  numberDisplayed
}) => {
  const options = getOptions(extraOption, allSelection);
  return (
    <CustomPicky
      selectedOption={values}
      setSelectedOption={(v) => {
        setCountry((v as MultiValue<SelectOption>).map((v) => v.value));
      }}
      getOptions={options}
      className={className ? className : 'customselect-small'}
      classNamePrefix={classNamePrefix}
      isSearchable={isSearchable}
      isClearable={isClearable}
      addAllItem={allSelection}
      typeLabel={'countries'}
      placeholder={placeholder}
      numberDisplayed={numberDisplayed ? numberDisplayed : 3}
    />
  );
};

export default MultiCountryDropdown;
